import React from "react";
import ImageCard from "./ImageCard";
import "./ShowCase.css";

// Manually import images up to 35
import img1 from "./showcaseImages/1.jpeg";
import img2 from "./showcaseImages/2.jpeg";
import img3 from "./showcaseImages/3.jpeg";
import img4 from "./showcaseImages/4.jpeg";
import img5 from "./showcaseImages/5.jpeg";
import img6 from "./showcaseImages/6.jpeg";
import img7 from "./showcaseImages/7.jpeg";
import img8 from "./showcaseImages/8.jpeg";
import img9 from "./showcaseImages/9.jpeg";
import img10 from "./showcaseImages/10.jpeg";
import img11 from "./showcaseImages/11.jpeg";
import img12 from "./showcaseImages/12.jpeg";
import img13 from "./showcaseImages/13.jpeg";
import img14 from "./showcaseImages/14.jpeg";
import img15 from "./showcaseImages/15.jpeg";
import img16 from "./showcaseImages/16.jpeg";
import img17 from "./showcaseImages/17.jpeg";
import img18 from "./showcaseImages/18.jpeg";
import img19 from "./showcaseImages/19.jpeg";
import img20 from "./showcaseImages/20.jpeg";
import img21 from "./showcaseImages/21.jpeg";
import img22 from "./showcaseImages/22.jpeg";
import img23 from "./showcaseImages/23.jpeg";
import img24 from "./showcaseImages/24.jpeg";
import img25 from "./showcaseImages/25.jpeg";
import img26 from "./showcaseImages/26.jpeg";
import img27 from "./showcaseImages/27.jpeg";
import img28 from "./showcaseImages/28.jpeg";
import img29 from "./showcaseImages/29.jpeg";
import img30 from "./showcaseImages/30.jpeg";
import img31 from "./showcaseImages/31.jpeg";
import img32 from "./showcaseImages/32.jpeg";
import img33 from "./showcaseImages/33.jpeg";

export default function ShowCase(params) {
  const images = [
    { img_id: "img15", img_src: img15, img_name: "" },

    { img_id: "img1", img_src: img1, img_name: "" },
    { img_id: "img16", img_src: img16, img_name: "" },
    { img_id: "img7", img_src: img7, img_name: "" },

    { img_id: "img2", img_src: img2, img_name: "" },
    { img_id: "img3", img_src: img3, img_name: "" },
    { img_id: "img4", img_src: img4, img_name: "" },
    { img_id: "img5", img_src: img5, img_name: "" },
    { img_id: "img6", img_src: img6, img_name: "" },
    { img_id: "img8", img_src: img8, img_name: "" },
    { img_id: "img9", img_src: img9, img_name: "" },
    { img_id: "img10", img_src: img10, img_name: "" },
    { img_id: "img11", img_src: img11, img_name: "" },
    { img_id: "img12", img_src: img12, img_name: "" },
    { img_id: "img13", img_src: img13, img_name: "" },
    { img_id: "img14", img_src: img14, img_name: "" },
    { img_id: "img17", img_src: img17, img_name: "" },
    { img_id: "img18", img_src: img18, img_name: "" },
    { img_id: "img19", img_src: img19, img_name: "" },
    { img_id: "img20", img_src: img20, img_name: "" },
    { img_id: "img21", img_src: img21, img_name: "" },
    { img_id: "img22", img_src: img22, img_name: "" },
    { img_id: "img23", img_src: img23, img_name: "" },
    { img_id: "img24", img_src: img24, img_name: "" },
    { img_id: "img25", img_src: img25, img_name: "" },
    { img_id: "img26", img_src: img26, img_name: "" },
    { img_id: "img27", img_src: img27, img_name: "" },
    { img_id: "img28", img_src: img28, img_name: "" },
    { img_id: "img29", img_src: img29, img_name: "" },
    { img_id: "img30", img_src: img30, img_name: "" },
    { img_id: "img31", img_src: img31, img_name: "" },
    { img_id: "img32", img_src: img32, img_name: "" },
    { img_id: "img33", img_src: img33, img_name: "" },
  ];

  return (
    <>
      <head>
        <meta
          title="description"
          content="VR GLOBAL CONSULTING is a well reputed ISO Certification consulting firm
      with the services across the globe. A trusted partner for all ISO and CMMI
      certification needs."
        />
      </head>
      <div className="container Showcasepage justify-content">
        <br />
        <br />
        <header className="text-center title1 py-4">
          <h1 className="head1 pt-2">Showcase</h1>
        </header>
      </div>
      <div className="image-container">
        <div className="row">
          {images.map((image, index) => (
            <ImageCard
              key={index}
              imageUrl={image.img_src}
              description={image.img_name}
              id={image.img_id}
            />
          ))}
        </div>
      </div>
    </>
  );
}
