import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import FrontEndApp from "./FrontEndApp";
const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/*" element={<FrontEndApp />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
