// import '../style.css'
import "./About.css";
import profileImage from "../Assets/ProfileImg1.jpg";

import "bootstrap/dist/css/bootstrap.min.css";

export default function AboutPage(params) {
  return (
    <>
      <head>
        <body>
          <meta
            name="description"
            content="Founded and led by Ramesh Kumar Pola, VR Global Consulting excels in providing comprehensive International Management System Standards services. With over 25 years of ISO auditing experience, Mr. Pola has guided the company to serve 500+ clients globally across various industries. Our services include Certification, Assessment, Process Implementation, and Training, ensuring operational efficiency and compliance with international standards. Accredited by a globally recognized body, we are committed to excellence and customer satisfaction."
          />
          <meta
            name="keywords"
            content="ISO 9001:2015, Best ISO Certification, ISO Certification in Hyderabad"
          />
          <meta name="title" content="Quality management systems" />
          <link rel="canonical" href="https://vrglobalconsulting.com/About" />
        </body>
      </head>
      <div className="container  AboutPage justify-content">
        <br />
        <br />
        <header className="text-center  title1 py-4">
          <h1>About Us - VR Global Consulting</h1>
        </header>
        <div className="row about-company-row bloginfo">
          <div id="about" className="company-info col-md-8">
            <p>
              Founded and led by Ramesh Kumar Pola, VR Global Consulting is
              driven by a vision to excel in providing comprehensive
              International Management System Standards services. With over 25+
              years of professional experience as an ISO auditor, Mr.Pola brings
              unparalleled expertise and insight to the forefront of our
              operations. Under his guidance, our company has successfully
              served over 500 clients, spanning diverse industries and sectors
              across the globe.
            </p>
            <br />
            <p>
              VR Global Consulting has rapidly expanded its reach both
              nationally and internationally, earning a reputation for
              excellence and reliability. Our client portfolio includes esteemed
              organizations in software development, engineering, banking,
              manufacturing, healthcare, real estate, charitable institutions,
              government agencies, and more.
            </p>
            <br />
            <p>
              At VR Global Consulting, we are dedicated to surpassing our
              clients expectations by consistently delivering tailored
              solutions. Our independent assessments not only enhance
              operational efficiency but also augment the effectiveness of our
              clients management systems.
            </p>
            <br />
            <p>
              Driven by a commitment to excellence, we endeavor to lead the
              industry in Certification, Assessment, Process Implementation, and
              Training services, ensuring both cost-effectiveness and
              uncompromising quality. We have cultivated partnerships with
              esteemed private organizations, governmental bodies, and law
              enforcement agencies. Accredited by a globally recognized
              International Accreditation Body, our certifications hold sway
              worldwide, endorsed by the prestigious International Accreditation
              Forum (IAF).
            </p>
          </div>
          <div className="col-md-4 profileBanner flex-column">
            <div className="profile-img">
              <img src={profileImage} alt="" />
              <p className="h6 pt-4">
                Mr. Ramesh Kumar Pola <br /> Founder & CEO of VR Global
                Consulting
              </p>
            </div>
          </div>
        </div>
        <div id="quality-policy " className="About3">
          <h2>Quality Policy</h2>
          <p>
            At VR Global Consulting, our mission is to facilitate the seamless
            adoption of industry best practices, aligning with International
            Standards to deliver unparalleled Products & Services of exceptional
            quality. We operate within a professional and adaptable framework,
            aspiring to attain world-class status while consistently exceeding
            customer expectations. Committed to upholding all legal and
            statutory requirements, we continually refine our services,
            leveraging cutting-edge technologies and engaging industry
            professionals to ensure customer satisfaction.
          </p>
        </div>

        <div id="quality-objectives" className="About4">
          <h2>Quality Objectives</h2>
          <ol>
            <li>
              <strong>Customer-Centric Approach:</strong> <br /> We meticulously
              analyze customer requirements and execute service delivery with
              precision and timeliness.
            </li>
            <li>
              <strong>Employee Development:</strong> <br /> Through targeted
              training programs, we empower our employees to better serve our
              clients, fostering continuous improvement and growth.
            </li>
            <li>
              <strong>Resource Optimization:</strong> <br /> We employ rigorous
              measurement, monitoring, and training protocols to optimize
              resource utilization and enhance operational efficiency.
            </li>
            <li>
              <strong>Regulatory Compliance:</strong> <br /> We adhere to
              customer specifications, codes, statutory regulations, and safety
              standards to ensure compliance and mitigate risks.
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}
