export default function Blog3(params) {
  return (
    <>
      <head>
        <meta name="title" content="CMMI certification" />
        <meta
          name="description"
          content="Find best ISO certificate for your organization by talking to an expert at vr global consulting"
        />
        <meta title="author" content="Ramesh Kumar Pola" />
      </head>
      <div className="container">
        <div className="blog px-4 ">
          <div className="Blogpost">
            <div className="bloginfo px-4">
              <br />
              <p className="h3 fw-semibold text-center">
                How to select suitable ISO standard for your organization
              </p>
              <br />
              <p>
                ISO Certification is the need of the hour for every organization
                in today's world. Irrespective of the size of the organization,
                whether it is a one person organization or a very big
                organization with huge manpower. The ISO Certification is
                applicable to every sector whether in Service sector, Product
                manufacturing, Software in Private sector, Public sector or
                Government.
              </p>
              <br />
              <div className="row align-items-center">
                <h4>
                  <b>ISO 9001:2015</b>{" "}
                </h4>
                <p>
                  ISO 9001:2015 is Quality Management System and is applicable
                  to all the organizations without any limitation in size or
                  sector they operate.
                </p>
                {/* QMS */}
                <h4>
                  <b>ISO 14001:2015 </b>{" "}
                </h4>
                <p>
                  ISO 14001:2015 is Environmental Management System: Any
                  organization wishes to identify, manage, monitor and control
                  their environmental issues in a holistic manner requires this
                  standard. Most of the organizations involving in waste
                  disposal, carbon emissions, chemicals, manufacturing sectors
                  with an intention to keep the environment clean.
                </p>
                {/* EMS */}
                <h4>
                  <b>ISO 45001:2018 </b>{" "}
                </h4>
                <p>
                  SO 45001:2018 is Occupational Health and Safety Management
                  System: Any organization with an intention to improve and
                  provide a safe and healthy workplace for the employees. This
                  standard helps to prevent work related injury and ill health
                  to workers. Most of the manufacturing sectors need this
                  implementation of the standard.
                </p>
                {/* OHS */}
                <h4>
                  <b>ISO 22000:2018</b>{" "}
                </h4>
                <p>
                  ISO 22000:2018 is Food Safety Management System: This standard
                  is an utmost important standard for the organizations related
                  to Food sector (food processing, food manufacturing, food
                  service sectors). Implementation of this standard helps the
                  organizations to have a clean, hygienic food for its end users
                  or customers.
                </p>
                {/* abc */}
                <h4>
                  <b>ISO 27001:2022</b>{" "}
                </h4>
                <p>
                  ISO 27001:2022 is Information Security Management System deals
                  with the IT software or data related companies where
                  information security is of great concern to give the customer
                  confidence.
                </p>
                <h4>
                  <b>ISO 20000-1:2018</b>{" "}
                </h4>
                <p>
                  ISO 20000-1:2018 is Information Technology Service Management
                  System provides the framework to assess and demonstrate the
                  ability to meet the customer requirements more effectively
                  through the implementation of a robust IT system in the
                  organization. It demonstrates your reliability and high
                  quality of services. These certificates will give a
                  competitive advantage to your organization in the market.
                </p>
                <h4>
                  <b>ISO 21001:2018</b>{" "}
                </h4>
                <p>
                  ISO 21001:2018 is Educational Organizations Management System
                  an International standard developed by International
                  Organization for Standardization. This standard provides the
                  management tools for organizations that offer educational
                  products or services. This standard intends to help the
                  educational service providers meet the students, parents
                  needs, expectations and requirements. Implementation of ISO
                  21001:2018 in an organization gives a very good competitive
                  advantage and the trust in the minds of customers.
                </p>
                <h4>
                  <b>ISO 13485:2016</b>{" "}
                </h4>
                <p>
                  ISO 13485:2016 is Medical Device Quality Management System
                  deals with the Quality Management System of the Medical
                  Devices in manufacturing or service sector related services
                  focusing on the best practices followed in the during the
                  process. This standard is a vital standard serving the pharma
                  sectors. This standard is very important to deliver the
                  products or services consistently meeting the customer and
                  applicable statutory and regulatory requirements.
                </p>
              </div>
            </div>
            <br />
            <br />
            <b>
              <p className="px-4">
                {" "}
                For all types of ISO & CMMI Certification, please contact VR
                GLOBAL CONSULTING <br />
                <b>website:</b>{" "}
                <a href="http://www.vrglobalconsulting.com">
                  www.vrglobalconsulting.com
                </a>{" "}
                <br />
                <b>e-mail: </b>
                pola@vrglobalconsulting.com
              </p>
            </b>
          </div>
        </div>
      </div>
    </>
  );
}
