import { Route, Routes } from "react-router-dom";
import HomePage from "./components/Home/HomePage";
import AboutPage from "./components/About/AboutPage";
import ShowCase from "./components/Showcase/ShowCase";
import ContactPage from "./components/Contact/ContactPage";
import NavBarComponent from "./components/Navbar/NavBarComponent";
import Services from "./components/Our Services/Services";
import BlogPage from "./components/Blog/BlogPage";
import Blog1 from "./components/Blog/Blog1";
import Blog2 from "./components/Blog/Blog2";
import Blog3 from "./components/Blog/Blog3";
import SocialBanner from "./components/SocialBanner/SocialBanner";
export default function FrontEndApp(params) {
  return (
    <>
      <div className="fixed-top">
        <SocialBanner />
        <NavBarComponent />
      </div>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/About-us" element={<AboutPage />} />
        <Route path="/ShowCase" element={<ShowCase />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/BlogLanding" element={<BlogPage />} />
        <Route path="/Contact" element={<ContactPage />} />
        <Route path="/Blog1" element={<Blog1 />} />
        <Route path="/Blog2" element={<Blog2 />} />
        <Route path="/Blog3" element={<Blog3 />} />
      </Routes>
    </>
  );
}
