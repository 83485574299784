import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./Services.css";
const Services = () => {
  return (
    <div className="our-services-container container pb-5">
      <br />
      <br />
      <header className="text-center  title1 py-4">
        <h1>Our Services</h1>
      </header>
      {/* <Accordion defaultActiveKey="0"> */}
      <head>
        <body>
          <meta
            name="title"
            content="VR GLOBAL CONSULTING caters to the needs of all sectors in product or services viz., Production, Manufacturing, ITES, SAAS, Hardware, Service in government, private sector organizations.  Independent assessments not only enhance operational efficiency but also augment the effectiveness of clients management systems, enhance their process maturity and capability across various industries."
          />

          <meta
            name="description"
            content="Discover the range of services offered by VR Global Consulting. Led by ISO expert Ramesh Kumar Pola, we specialize in International Management System Standards, including certification, assessment, process implementation, and training. Our services ensure compliance, operational efficiency, and excellence across various industries."
          />
          <meta
            name="keywords"
            content="ISO 9001, ISO 14001, ISO 45001, ISO 27001, ISO 20000-1,
            ISO 22000, ISO 13485, ISO 21001, ISO 27701 and many more "
          />
          <link
            rel="canonical"
            href="https://vrglobalconsulting.com/OurServices"
          />
        </body>
      </head>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="accordion-header">
            <b> ISO 9001 (Quality Management Systems)</b>
          </Accordion.Header>
          <Accordion.Body>
            ISO 9001 (Quality Management Systems) specifies requirements for a
            quality management system when an organization:
            <br />
            a) Needs to demonstrate its ability to consistently provide products
            and services that meet customer and applicable statutory and
            regulatory requirements, and <br />
            b) Aims to enhance customer satisfaction through the effective
            application of the system, including processes for improvement of
            the system and the assurance of conformity to customer and
            applicable statutory and regulatory requirements. All the
            requirements of ISO 9001 are generic and are intended to be
            applicable to any organization, regardless of its type or size, or
            the products and services it provides.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="accordion-header">
            <b> ISO 14001 (Environment Management System)</b>
          </Accordion.Header>
          <Accordion.Body>
            An environmental management system helps organizations identify,
            manage, monitor and control their environmental issues in a
            “holistic” manner. It requires that an organization considers all
            environmental issues relevant to its operationsand resources’ use
            and efficiency.
            <br />
            This includes the need for continual improvement of an
            organization’s systems and approach to environmental concerns.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header className="accordion-header">
            <b> ISO 45001 (Occupational Health & Safety Management System)</b>
          </Accordion.Header>
          <Accordion.Body>
            The occupational health and safety (OH&S) management system, is a
            new international standard that provides a framework for an
            organization to manage risks and opportunities to help prevent
            work-related injury and ill health to workers. The intended outcome
            is to improve and provide a safe and healthy workplace. All of its
            requirements are designed to be integrated into an organization’s
            management and business processes.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header className="accordion-header">
            <b> ISO 22000 (Food Safety Management System)</b>
          </Accordion.Header>
          <Accordion.Body>
            Sets out the requirements for a food safety management system (FSMS)
            and can be certified to. It maps out what an organization needs to
            do to demonstrate its ability to control food safety hazards in
            order to ensure that food is safe. Fassai (Food Safety and Standards
            Authority of India): We do the Basic, State and Central
            certification for Fassai. A food license by which is issued by the
            FSSAI is regarded as a permit which is required to operate a
            food-related business and also in order to ensure good quality of
            food in your business and for the benefits one from government
            actions on non-compliances.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header className="accordion-header">
            <b> ISO 20000 (Information Technology Service Management)</b>
          </Accordion.Header>
          <Accordion.Body>
            ISO 20000 is the international IT service management (ITSM) standard
            that enables IT organizations in-house, outsourced or external to
            ensure that their ITSM processes are aligned both with the needs of
            the business and with international best practice. Helps
            organizations benchmark how they deliver managed services, measure
            service levels and assess their performance.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header className="accordion-header">
            <b> ISO 27001 (Information Security Management System)</b>
          </Accordion.Header>
          <Accordion.Body>
            Is the international standard that describes the requirements for
            ISMS (information security management system). ISO 27001 is
            supported by its code of practice for information security
            management, ISO/IEC 27002:2013. Achieving accredited certification
            to ISO 27001 provides an independent, expert assessment that
            information security is managed in line with international best
            practice and business objectives.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header className="accordion-header">
            <b> ISO 13485 (Standard for Medical Devices)</b>
          </Accordion.Header>
          <Accordion.Body>
            Specifies requirements for a quality management system where an
            organization needs to demonstrate its ability to provide medical
            devices and related services that consistently meet customer and
            applicable regulatory requirements. This can also be used by
            suppliers or external parties that provide product, including
            quality management system-related services to such organizations.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="12">
          <Accordion.Header className="accordion-header">
            <b> CMMI</b>
          </Accordion.Header>
          <Accordion.Body>
            We provide comprehensive CMMI (Capability Maturity Model
            Integration) services to organizations seeking to enhance their
            process maturity and capability across various industries. Our
            tailored approach encompasses assessment, implementation, and
            optimization phases, guiding clients through the intricacies of CMMI
            frameworks to streamline operations, improve product quality, and
            drive organizational growth. With a team of experienced consultants
            versed in CMMI best practices, we deliver customized solutions that
            align with our clients' unique needs and objectives, fostering a
            culture of continuous improvement and excellence.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header className="accordion-header">
            <b> CE Marking</b>
          </Accordion.Header>
          <Accordion.Body>
            CE marking is a mandatory conformity marking for certain products
            sold within the European Economic Area (EEA) since 1985.[1]The CE
            marking is also found on products sold outside the EEA that are
            manufactured in, or designed to be sold in, the EEA. This makes the
            CE marking recognizable worldwide even to people who are not
            familiar with the European Economic Area. It is in that sense
            similar to the FCC Declaration of Conformity used on certain
            electronic devices sold in the United States. The CE marking is the
            manufacturer's declaration that the product meets the requirements
            of the applicable EC directives.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header className="accordion-header">
            <b> GMP (Good Manufacturing Practice)</b>
          </Accordion.Header>
          <Accordion.Body>
            A GMP is a system for ensuring that products are consistently
            produced and controlled according to quality standards. It is
            designed to minimize the risks involved in any pharmaceutical
            production that cannot be eliminated through testing the final
            product.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9">
          <Accordion.Header className="accordion-header">
            <b> GDP (Good Distribution Practice)</b>
          </Accordion.Header>
          <Accordion.Body>
            Good distribution practices ensure maintaining product safety and
            quality during distribution across the supply chain and this
            practice is of significant importance for the pharmaceutical
            industry. Good Distribution Practices (GDP) Compliance report for
            Pharmaceutical Industry demonstrates Quality commitment & sound
            practices in the entire pharmaceutical distribution supply chain.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="10">
          <Accordion.Header className="accordion-header">
            <b> Firm Registration:</b>
          </Accordion.Header>
          <Accordion.Body>
            We do registration of firms (Individual, Partnership, Pvt. Ltd
            companies, LLP etc..) we help in getting the GST registration/PAN
            card.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="11">
          <Accordion.Header className="accordion-header">
            <b> MSME:</b>
          </Accordion.Header>
          <Accordion.Body>
            We help in getting the MSME certification to your organization.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Services;
