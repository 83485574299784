// import '../style.css'
import flowchart from "../Assets/Flowchart.jpeg";
import flowchart2 from "../Assets/flowchart2.jpeg";

export default function Blog2(params) {
  return (
    <>
      <head>
        <meta name="title" content="ISO Certification" />
        <meta
          name="description"
          content="VR Global Consulting is dedicated in surpassing clients expectations by
        consistently delivering tailored solutions. Dedicated experienced
        auditors with more than 25+ years of hands on experience in respective
        domains serving across the globe at a very economical fee."
        />
        <meta title="author" content="Ramesh Kumar Pola" />
      </head>
      <div className="container">
        <div className="blog">
          <div className="Blogpost">
            <div className="bloginfo px-4">
              <br />
              <p className="h3 text-center fw-semibold">
                What is the actual process of getting an ISO certification?
              </p>

              <ol className="Body-Text blog-list">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <li>
                      Collecting the filled-in enquiry form with required
                      details for sharing the proposal on ISO Certification.
                    </li>
                    <li>
                      Sharing the cost proposal along with the process details
                      on requested ISO Standard.
                    </li>
                    <li>
                      After confirmation of the order, filling the application
                      for registration.
                    </li>
                    <li>
                      Review of the application, Contract signing, Contract
                      review and Audit planning, scheduling the audit.
                    </li>
                    <li>
                      Conducting the Stage 1 audit to check the legal compliance
                      and adequacy of proper documentation followed in the
                      organization with respect to the ISO Standard along with
                      checking the records of practice in the organization.
                    </li>
                    <li>
                      In case of any Non Conformities observed during the stage
                      1 audit, sufficient time will be given to address the Non
                      Conformities and schedule for the shadow audit for
                      initiating the certification process.
                    </li>
                    <li>
                      Conducting the shadow audit checking the proper closure of
                      the raised NC's during stage 1 audit and initiating the
                      recommendation report for the certification.
                    </li>
                    <li>
                      Review of the recommendation report by respective
                      certification board and also with International
                      Accreditation Board for the release of the ISO
                      Certification.
                    </li>
                  </div>
                  <div className="col-md-6 text-center">
                    <br />
                    <img
                      src={flowchart}
                      alt="Flowchart 1"
                      className="img-fluid left-image"
                    />
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6 text-center">
                    <img
                      src={flowchart2}
                      alt="Flowchart 2"
                      className="img-fluid right-image"
                    />
                  </div>
                  <div className="col-md-6">
                    <br />
                    <li>
                      Once approved from respective certification board and from
                      International Accreditation Board, release of the ISO
                      Certification along with the details of the organization
                      live on respective certification board website as well as
                      on International Accreditation Board website <br /> (
                      <a href="https://www.iafcertsearch.org/">
                        www.iafcertsearch.org
                      </a>
                      ). This ensures the issued certificate is genuine and
                      valid across the globe. Once certified, accepted across
                      the globe.
                    </li>
                    <li>
                      Conducting the yearly surveillance audits to check the
                      compliance and renewal certificate will be issued on
                      successful yearly renewal. These audits are conducted on
                      yearly basis viz., 11th month, 23rd month. 11. Going for
                      re-certification after 3 years.
                    </li>
                  </div>
                </div>
              </ol>
              <br />
            </div>
            <b>
              <br />
              <br />
              <p className="px-4">
                {" "}
                For all types of ISO & CMMI Certification, please contact VR
                GLOBAL CONSULTING <br />
                <b>website:</b>{" "}
                <a href="http://www.vrglobalconsulting.com">
                  www.vrglobalconsulting.com
                </a>{" "}
                <br />
                <b>e-mail: </b>
                pola@vrglobalconsulting.com
              </p>
            </b>
          </div>
        </div>
      </div>
    </>
  );
}
