import "./Blogs.css";
import blog1 from "../Assets/article1img.jpeg";
import blog2 from "../Assets/blogcover2.jpeg";
import blog3 from "../Assets/blog3image.jpeg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function BlogPage(params) {
  const [blogCard1, setBlogCard1] = useState(false);
  const [blogCard2, setBlogCard2] = useState(false);

  const [blogCard3, setBlogCard3] = useState(false);

  const navigate = useNavigate();
  const blogsRedirect = (i) => {
    let page = `Blog${i}`;
    navigate(`/${page}`);
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  const copyToClipBoard = (i) => {
    let shareLink = `https://vrglobalconsulting.com/Blog${i}`;
    navigator.clipboard.writeText(shareLink).catch((err) => {
      console.error("Failed to copy: ", err);
    });
    if (i === 1) {
      setBlogCard1(!blogCard1);
    } else if (i === 2) {
      setBlogCard2(!blogCard2);
    } else {
      setBlogCard3(!blogCard3);
    }
  };
  return (
    <>
      <head>
        <body>
          <meta
            name="description"
            content="Explore insights and updates on International Management System Standards with VR Global Consulting's blog. Led by industry expert Ramesh Kumar Pola, our blog covers best practices, compliance tips, case studies, and the latest trends in certification, assessment, process implementation, and training. Stay informed and enhance your organization's efficiency and compliance with our expert articles and resources."
          />
          <meta
            name="keywords"
            content="ISO 22000, Food safety Management , Information security management system, ISMS"
          />
          <meta
            name="title"
            content="Information security management systems"
          />
          <link
            rel="canonical"
            href="https://vrglobalconsulting.com/BlogLanding"
          />
        </body>
      </head>
      <div className="container blogpage">
        <br />
        <br />
        <header className="text-center  title1 py-4">
          <h1>Blogs</h1>
        </header>

        <div className="container">
          <div className="row">
            <div className="col-md-4 d-flex">
              <div className="blog-card my-2 card">
                <div className="w-100" onClick={() => blogsRedirect(1)}>
                  <img src={blog1} alt="" className=" p-2 w-100" />
                  <p className="h4 fw-semibold px-2">
                    Don't Get Tricked by Fake ISO Certificates!
                  </p>

                  <p className=" px-2">
                    Imagine someone selling you a trophy for winning a race you
                    never even ran. That's what a fake ISO certificate is like
                    ................
                  </p>
                </div>
                <div className="m-2 blog-buttons">
                  <button
                    onClick={() => blogsRedirect(1)}
                    className="btn btn-dark  "
                  >
                    Read Article
                  </button>
                  <button
                    className={` ${
                      blogCard1
                        ? " btn btn-success mx-2"
                        : "btn btn-secondary mx-2"
                    }`}
                    onClick={() => copyToClipBoard(1)}
                  >
                    <i
                      className={`${
                        blogCard1
                          ? "bi bi-clipboard-check-fill"
                          : "bi bi-clipboard"
                      }`}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <br />

            {/*  */}

            <div className="col-md-4 d-flex">
              <div className="blog-card my-2 card">
                <div className="w-100" onClick={() => blogsRedirect(2)}>
                  <img src={blog2} alt="" className="p-2 w-100" />

                  <p className="h4 fw-semibold px-2">
                    What is the actual process of getting an iso ?{" "}
                  </p>

                  <p className=" px-2">
                    Earning ISO certification signifies an organization's
                    commitment to quality and best practices. This article
                    unveils the journey to achieving this prestigious
                    recognition. We'll walk you through each step, from initial
                    inquiry to ongoing maintenance of your certification.
                  </p>
                </div>
                <div className="m-2 blog-buttons">
                  <button
                    onClick={() => blogsRedirect(2)}
                    className="btn btn-dark  "
                  >
                    Read Article
                  </button>
                  <button
                    className={` ${
                      blogCard2
                        ? " btn btn-success mx-2"
                        : "btn btn-secondary mx-2"
                    }`}
                    onClick={() => copyToClipBoard(2)}
                  >
                    <i
                      className={`${
                        blogCard2
                          ? "bi bi-clipboard-check-fill"
                          : "bi bi-clipboard"
                      }`}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
            <br />
            {/*  */}
            <div className="col-md-4 d-flex">
              <div className="blog-card my-2 card">
                <div className="w-100" onClick={() => blogsRedirect(3)}>
                  <img src={blog3} alt="" className="p-2 w-100" />

                  <p className="h4 fw-semibold px-2">
                    How to select suitable ISO standard for your organization{" "}
                  </p>

                  <p className=" px-2">
                    We delve into the intricacies of prominent ISO standards,
                    including ISO 9001 (Quality Management), ISO 14001
                    (Environmental Management), ISO 45001 (Occupational Health
                    and Safety), ISO 22000 (Food Safety), and ISO 27001
                    (Information Security).
                  </p>
                </div>
                <div className="m-2 blog-buttons">
                  <button
                    onClick={() => blogsRedirect(3)}
                    className="btn btn-dark  "
                  >
                    Read Article
                  </button>
                  <button
                    className={` ${
                      blogCard3
                        ? " btn btn-success mx-2"
                        : "btn btn-secondary mx-2"
                    }`}
                    onClick={() => copyToClipBoard(3)}
                  >
                    <i
                      className={`${
                        blogCard3
                          ? "bi bi-clipboard-check-fill"
                          : "bi bi-clipboard"
                      }`}
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}
