import "bootstrap/dist/css/bootstrap.min.css";
import brand from "../Assets/Brand.png";
import "./ContactPage.css";
import { useEffect, useState } from "react";
export default function ContactPage(params) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [Btn, setBtn] = useState("Submit");
  const [isActive, setIsActive] = useState(false);
  const emailHost = process.env.REACT_APP_EMAIL_HOST;
  const emailUsername = process.env.REACT_APP_EMAIL_USERNAME;
  const emailPassword = process.env.REACT_APP_EMAIL_PASSWORD;

  useEffect(() => {
    let timer;

    if (isActive) {
      timer = setTimeout(() => {
        setIsActive(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isActive]);

  const handleOpenMaps = () => {
    window.open("https://maps.app.goo.gl/1mhimK1qMqshEMVQ8");
  };

  const handleCloseClick = () => {
    setIsActive(false);
  };
  const sendAcknowledgment = async () => {
    const acknowledgmentBody = `Dear ${name},<br>Thank you for reaching out to VR Global Consulting. We have received your message and will get back to you as soon as possible.<br><br>Best regards, <br>The VR Global Consulting Team`;

    await window.Email.send({
      Host: emailHost,
      Username: emailUsername,
      Password: emailPassword,
      From: "vrglobalforms@gmail.com",
      To: email,
      Subject: "VR Global Consulting",
      Body: acknowledgmentBody,
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!name.trim() | !email.trim()) {
      setError("Please Enter Details Before Submitting.");
    } else if (!re.test(email)) {
      setError("Invaild Email!");
    } else if (phone.length !== 10) {
      setError("Invalid Phone Number");
    } else {
      setError("");
      setIsActive(true);

      const emailBody = `Dear Ramesh, <br>We got clients from VR Global Consulting Website. Hope you can connect them to provide services. The details are given below. <br>Thank You. <br> <b>Name:</b>  ${name} <br> Email: ${email} <br> Phone Number: ${phone}`;
      window.Email.send({
        Host: emailHost,
        Username: emailUsername,
        Password: emailPassword,
        From: "vrglobalforms@gmail.com",
        To: "vrglobalforms@gmail.com",
        Subject: "VR Global",
        Body: emailBody,
      })
        .then((message) => {
          setBtn("Sent");
          alert("Sent");
          sendAcknowledgment();
        })
        .catch((error) => {
          alert("Failed to send email. Please try again later.");
        });
    }
  };

  useEffect(() => {
    if (Btn === "Submit") {
      return;
    }

    setBtn("Sent");

    const timer = setTimeout(() => {
      setBtn("Submit");
      setName("");
      setEmail("");
      setPhone("");
    }, 3000);
    return () => clearTimeout(timer);
  }, [Btn]);
  return (
    <>
      <head>
        <body>
          <meta
            name="description"
            content="
            Redefine your business with VR GLOBAL CONSULTING
            For all types of ISO and CMMI International Accreditation and Compliance Certification needs, contact VR GLOBAL CONSULTING
            pola@vrglobalconsulting.com"
          />
          <meta
            name="keywords"
            content="ISO 45001, Information Technology for service management , Contact VR Global consulting for all ISO needs"
          />
          <meta
            name="title"
            content=" Contact VR global consulting for all ISO needs"
          />
          <link rel="canonical" href="https://vrglobalconsulting.com/Contact" />
        </body>
      </head>
      <div className="container contactpage ">
        <br />
        <br />
        <div className="brandBanner">
          <img src={brand} className="d-block img  brand" alt="" />
        </div>

        <div className="contactPageContainer">
          <div className="row contactDetailsRow">
            <div className="col-md-6 contactPageDetails">
              <p className="h3">
                {" "}
                <i className="bi bi-person"></i> Contact Us
              </p>
              <div className="contactDetails">
                <button onclick="window.location.href='tel:9381578739';">
                  Call Us
                </button>
                {/* <p>
                  {" "}
                  <span className="bi bi-telephone"></span> Phone: +91
                  9963553332
                </p> */}

                <p>
                  {" "}
                  <span className="bi bi-envelope"></span> Email: <span> </span>
                  <a href="mailto:pola@vrglobalconsulting.com">
                    pola@vrglobalconsulting.com
                  </a>
                </p>
              </div>
              <p className="h3">
                {" "}
                <i className="bi bi-geo-alt"></i> Location
              </p>

              <div className="locationDetails">
                <address>
                  <p>Registered office: #66C, Vengal Rao Nagar </p>
                  <p>Hyderabad 500038, Telangana, India</p>
                  <button
                    onClick={handleOpenMaps}
                    className="btn btn-dark mt-2"
                  >
                    Open in Google Maps
                  </button>
                </address>
              </div>
            </div>

            <div className="col-md-6 ">
              <div className="contactPageBookCall">
                <h3 className="title1 h4 text-dark " style={{ color: "white" }}>
                  Book A Call
                </h3>
                <p className="h6 mt-3 text-center text-danger">{error}</p>

                <div className="submitFormContainer">
                  <form
                    className="mb-5"
                    onSubmit={formSubmit}
                    id="contactForm"
                    name="contactForm"
                  >
                    <div className="forms submitForm">
                      <div className=" p-3 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Your name"
                        />
                      </div>
                      <div className=" p-3 form-group submitContactFormGroup">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className=" p-3 form-group">
                        <input
                          type="number"
                          className="form-control"
                          name="phone"
                          id="phone"
                          placeholder="Phone number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="contactBtn">
                        <button
                          id="contactBtn"
                          className={`btn btn-primary ${Btn}-status`}
                          onClick={formSubmit}
                        >
                          {Btn}
                        </button>
                        <div className={`toast ${isActive ? "active" : ""}`}>
                          <div className="toast-content">
                            <i className="bi bi-check check"></i>
                            <div className="message">
                              <span className="text text-1 ">
                                Message Sent Successfully <br />
                              </span>
                              <span className="text text-2">
                                Thank you for reaching out. We will contact you
                                as soon as possible.
                              </span>
                            </div>
                          </div>
                          <div
                            className={`progress ${isActive ? "active" : ""}`}
                          ></div>

                          <i
                            className="bi bi-x close"
                            onClick={handleCloseClick}
                          ></i>
                        </div>
                      </div>

                      <span className="submitting"></span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
