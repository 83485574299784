import "./SocialBanner.css";

export default function SocialBanner(params) {
  const whatsappURL = () => {
    window.location = "https://api.whatsapp.com/send?phone=919963553332";
  };
  return (
    <div className="SocialBanner row">
      <div className="col-md-5 d-flex">
        <a href="tel:+91 9963553332">
          <button className="btn whatsapp-btn">
            <p className="  ">
              {" "}
              <span class="bi bi-phone  m-1"></span>+91 9963553332
            </p>
          </button>
        </a>

        <button className="btn whatsapp-btn float-end" onClick={whatsappURL}>
          <p className="  ">
            {" "}
            <span class="bi bi-whatsapp  m-1"></span>Chat With Us
          </p>
        </button>
      </div>

      <div className="col-md-7">
        <p className=" m-2  text-light float-end">
          {" "}
          <i class="bi bi-envelope  m-1"></i>
          pola@vrglobalconsulting.com
        </p>
      </div>
    </div>
  );
}
